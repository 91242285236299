import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout, { Seo } from '../../components/Layout';
import Row, { Col } from '../../components/Elements/Grid';
import Cover from '../../components/Elements/Cover';
import PageSection from '../../components/Elements/PageSection';

import LinkButton from '../../components/Elements/LinkButton';
import GetReady from '../../components/Parts/GetReady';
import Icon from '../../components/Elements/Icon';
import getYears from '../../helpers/getYears';
import withTranslations from '../../i18n/withTranslations';

const Page = () => {
  const { t } = useTranslation('onzeAanpak');

  return (
    <Layout>
      <Seo title={t('seoTitle')} />
      <Cover>
        <PageSection center className="steps">
          <h1>{t('title')}</h1>
          <div className="plan">
            <div className="step">
              <h3>{t('getReady.title')}</h3>
              <div className="details">
                <div className="icon">
                  <Icon type="exo-cola" />
                </div>
                <div className="story">
                  <p>{t('getReady.description')}</p>
                </div>
              </div>
            </div>
            <div className="step">
              <h3>{t('plan.title')}</h3>
              <div className="details">
                <div className="icon">
                  <Icon type="lightbulb" />
                </div>
                <div className="story">
                  <p>{t('plan.description', { years: getYears() })}</p>
                </div>
              </div>
            </div>
            <div className="step">
              <h3>{t('playbook.title')}</h3>
              <div className="details">
                <div className="icon">
                  <Icon type="exo-ansible" />
                </div>
                <div className="story">
                  <p>{t('playbook.description')}</p>
                </div>
              </div>
            </div>
            <div className="step">
              <h3>{t('delivery.title')}</h3>
              <div className="details">
                <div className="icon">
                  <Icon type="space-shuttle" />
                </div>
                <div className="story">
                  <p>{t('delivery.description')}</p>
                </div>
              </div>
            </div>
            <div className="step">
              <h3>{t('collaboration.title')}</h3>
              <div className="details">
                <div className="icon">
                  <Icon type="heart" />
                </div>
                <div className="story">
                  <p>{t('collaboration.description')}</p>
                </div>
              </div>
            </div>
          </div>
        </PageSection>
        <PageSection slide center>
          <Row>
            <Col className="quote">
              <h2 className="text-center">{t('customerQuote.title')}</h2>
              <p>
                &ldquo;
                {t('customerQuote.description.p1')}
                &rdquo;
                <br />
                <br />
                &ldquo;
                {t('customerQuote.description.p2')}
                &rdquo;
              </p>
              <div className="foot">
                <h3>Bas van Bokhorst, Greenberry</h3>
                <LinkButton to="/onze-aanpak/cases#greenberry">
                  {t('customerQuote.watchCase')}
                </LinkButton>
              </div>
            </Col>
          </Row>
        </PageSection>
        <GetReady title={t('getReadyTitle')} image="footerOurApproach" />
      </Cover>
    </Layout>
  );
};

export default withTranslations(Page);
